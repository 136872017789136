import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { Provider } from 'react-redux';
import {enableMapSet} from "immer"
import { Startup } from './startup';
import { store } from './shared';
import * as URI from "uri-js";

import 'bootstrap/dist/css/bootstrap.min.css';
import { User } from 'oidc-client-ts';

enableMapSet();

Startup();

const onSigninCallback = (_user: User | void): void => {
  window.history.replaceState(
      {},
      document.title,
      window.location.pathname
  )
}



const authorityMap:Record<string, string> = {
  "https://localhost:5002": "https://localhost:5000",
  "https://poppinpayadminuistaging.z21.web.core.windows.net": "https://identity-staging.poppinpay.com",
  "https://adminui-staging.poppinpay.com": "https://identity-staging.poppinpay.com",
  "https://adminui.poppinpay.com": "https://identity.poppinpay.com",
  "https://admin.poppinpay.com": "https://identity.poppinpay.com",
};

const authority:string = authorityMap[window.location.origin] ?? "https://localhost:5000";

const oidcConfig:AuthProviderProps = {
  authority: authority,
  client_id: "poppinpay-web-admin-ui",
  scope: 'openid profile admin-v1',
  redirect_uri: URI.resolve(window.location.href, '/signin-oidc'),
  post_logout_redirect_uri:URI.resolve(window.location.href, '/signout-callback-oidc'),
  onSigninCallback: onSigninCallback
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <Provider store={store}>
    <React.StrictMode>
      <AuthProvider {...oidcConfig}>
        <App />
      </AuthProvider>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
