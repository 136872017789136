import { Nav } from "react-bootstrap"
import SideBarDropdown from "./sideBarDropdown"
import SideBarItem from "./sideBarItem"

export type SideBarProps = {
    children: React.ReactNode
}

const SideBar = (props:SideBarProps) => {
    return <Nav className={'flex-column'}>{props.children}</Nav>
  }
  
  SideBar.Dropdown = SideBarDropdown
  SideBar.Item = SideBarItem
  
  export default SideBar