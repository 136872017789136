import React, { useEffect } from 'react';
import './App.css';
import Menu from './components/Menu/menu';
import { BrowserRouter, Outlet, Route, Routes, Navigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import HomeScreen from './screens/Home/home';
import LoginScreen from './screens/Login/login';
import { AdminTeamMembersScreen } from './screens/AdminTeamMembers/adminTeamMembersScreen';
import { ApiClient } from './shared';

const Error = () => {
  return (
      <div>
          uh oh
      </div>
  )
};

const Loading = () => {
  return (
      <div>
          <Spinner animation="border" variant="warning" />
      </div>
  )
};

const Dashboard = () => {
  const auth = useAuth();

  return (

    <div className="d-flex bg-neutral">
          <div className='col-2'>
            <div className='vh-100 bg-poppinpay'>
              { auth.isAuthenticated && <Menu /> }
            </div>
          </div>
          <main className='col-10 d-flex'>
            <Outlet />
          </main>
    </div>    
  );
};

function App() {
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated) {
      ApiClient.setAccessToken(auth.user!.access_token);
    } else {
      ApiClient.setAccessToken(null);
    }
  }, [auth]);


  switch (auth.activeNavigator) {
      case "signinSilent":
          return <div>Signing you in...</div>;
      case "signoutRedirect":
          return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
      return <div>Loading...</div>;
  }

  if (auth.error) {
      return <div>Oops... {auth.error.message}</div>;
  }

  if (!auth.isAuthenticated) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />}>
            <Route path="" element={<LoginScreen />} />
            <Route path="signin-oidc" element={<Navigate to="/" replace={true} />} />
            <Route path="signout-callback-oidc" element={<Navigate to="/" replace={true} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Dashboard />}>
                <Route path="" element={<HomeScreen />} />
                <Route path="signin-oidc" element={<Navigate to="/" replace={true} />} />
                <Route path="signout-callback-oidc" element={<Navigate to="/" replace={true} />} />
                { /*
                <Route path="site/:siteId" element={<Site />}>
                    <Route path="" element={<SiteSettings />} />
                    <Route path="open-ai" element={<OpenAiSettings />} />
                    <Route path="wordpress" element={<WordPressSettings />} />
                    <Route path="wix" element={<WixSettings />} />
                </Route>
                */ }
                <Route path="error" element={<Error />} />
                <Route path="admin">
                  <Route path="team-members" element={<AdminTeamMembersScreen />} />
                </Route>
            </Route>
        </Routes>
    </BrowserRouter>    
  );

  /*
  <div className="row bg-neutral">
          <div className='col-2' >
            <div className='vh-100 bg-poppinpay'>
              <Menu></Menu>
            </div>
          </div>

          <div className='col-10'>
            <div>
              Hello {auth.user?.profile.sub}{" "}
              <button onClick={() => void auth.signoutRedirect()}>Log out</button>
            </div>
            <Dashboard/>
          </div>
    </div>
  */
}

export default App;
