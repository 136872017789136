import { useEffect, useState } from "react";
import { TeamMember, teamMembers, useAppDispatch, useAppSelector } from "../../shared";

import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react';
import {ColDef, IServerSideDatasource, IServerSideGetRowsParams} from "ag-grid-community";

import "ag-grid-enterprise";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { Button } from "react-bootstrap";

const TeamMemberActionsCell = (props:CustomCellRendererProps<TeamMember>) => {
    return <><Button onClick={() => {}} variant="link">Edit</Button> | <Button onClick={() => {}} variant="link">Delete</Button></>;
}

export const AdminTeamMembersScreen = () => {

    const dispatch = useAppDispatch();

    const s = useAppSelector(state => {
        return {
        }
    });

    const [columnDefs] = useState<ColDef<TeamMember>[]>([
        { field: 'firstName', headerName: 'First Name', flex: 1, },
        { field: 'lastName', headerName: 'Last Name', flex: 1 },
        { field: 'emailAddress', headerName: 'Email Address', flex:2  },
        { valueGetter: () => null, headerName: 'Actions', cellRenderer: TeamMemberActionsCell  }
    ]);

    const datasource:IServerSideDatasource = {
        getRows: async (params: IServerSideGetRowsParams) => {
            const rowsResult = await dispatch(teamMembers.getRows(params.request));
            if (rowsResult === null) {
                params.fail();
            } else {
                params.success(rowsResult);
            }
        }
    };

    return (
        <div className="m-4 d-flex flex-fill flex-column">
            <div className="d-flex justify-content-between mb-4">
                <span className="h2">Team Members</span>
                <Button variant="primary">Add Team Member</Button>
            </div>

            <AgGridReact
                columnDefs={columnDefs}
                rowModelType="serverSide"
                serverSideDatasource={datasource}
                // autoSizeStrategy={{type:'fitGridWidth'}}
                defaultColDef={{sortable: true, resizable: false, filter:true, enableCellChangeFlash: true}}
                enableAdvancedFilter={true}
                className="ag-theme-quartz flex-fill"
                pagination={true}
                containerStyle={{}}
            />
        </div>        
    );
}