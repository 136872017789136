import React from 'react'

const UserInfo = () => {
  return (
    <div className='mx-2 mt-5 mb-2 d-flex flex-row align-items-center justify-content-center'>
        <img className='rounded-circle' src='https://i.pravatar.cc/50?img=3'></img>
        <div className='px-2'>
            <span className='text-white fw-bold'>Henry Cruz Mulet</span><br/>
            <span className='text-white fs-6 fw-lighter'>Administrator</span>
        </div>
    </div>
  )
}

export default UserInfo
