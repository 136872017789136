import { useState } from "react";
import { Collapse, Container, Nav } from "react-bootstrap";

export type SideBarDropdownProps = {
    title:string
    children: React.ReactNode
}

const SideBarDropdown = (props:SideBarDropdownProps) => {
    const [open, setOpen] = useState(false);
    return (
      <Container fluid className={'p-0 m-0'}>
        <Nav.Item onClick={() => setOpen(!open)} className={'p-1 m-0 text-white d-flex'} role="button">
            {props.title}
            {open && <i className='bx bx-chevron-up ms-auto' style={{color:'#fff'}} ></i>}
            {!open && <i className='bx bx-chevron-down ms-auto' style={{color:'#fff'}} ></i>}
        </Nav.Item>
        <Collapse in={open}>
          <div className={'ps-4'}>{props.children}</div>
        </Collapse>
      </Container>
    )
  }
  export default SideBarDropdown