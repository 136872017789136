import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../';
import { IServerSideGetRowsRequest } from 'ag-grid-enterprise';
import { ApiClient, IsApiError, RowsResult } from '../../azure/apiClient';
import { useAuth } from 'react-oidc-context';

type TeamMember = {
    firstName:string
    lastName:string
    emailAddress:string
}

export interface TeamMembersState
{

}

const initialState:TeamMembersState = {
	initialized: false,
    initializing: false,
};

const teamMembersSlice = createSlice({
	name: 'teamMembers',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {

	}
});

export const { } = teamMembersSlice.actions

export const getRows = (request:IServerSideGetRowsRequest): AppThunk<Promise<RowsResult<TeamMember>|null>> => async (dispatch, getState):Promise<RowsResult<TeamMember>|null> => {
    const teamMembers = await ApiClient.getTeamMembers(request);
    if (IsApiError(teamMembers))
    {
        return null;
    }
    return teamMembers;
};

export const reducer = teamMembersSlice.reducer