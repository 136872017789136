import { Container, Nav } from "react-bootstrap"

export type SideBarItemProps = {
    title:string
    onClick:() => void
}
const SideBarItem = (props:SideBarItemProps) => {
    return (
      <Container fluid className={'p-0 m-0'}>
        <Nav.Item onClick={props.onClick} className={'p-1 m-0 text-white d-flex'} role="button">
            {props.title}
        </Nav.Item>
      </Container>
    )
  }
  
  export default SideBarItem