import { Internationalization } from "./shared";
import * as translationsEN from './locale/en.json';

export const Startup = () => {
    const translations = {
        en: translationsEN
    };

    const fallbackLanguage = { languageTag: "en", isRTL: false };
    const { languageTag } = fallbackLanguage;

    Internationalization.initialize(translations);
    Internationalization.setLanguage(languageTag);
};