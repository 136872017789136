import { configureStore } from '@reduxjs/toolkit';
import { api, teamMembers } from './reducers';

export const createStore = () => {
  return configureStore({
    reducer: {
      api: api.reducer,
      teamMembers: teamMembers.reducer
    }
  });
}