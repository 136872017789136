import React from 'react'
import './home.css'
import UserInfo from '../../components/UserInfo/userInfo'

function HomeScreen() {
  return (
    <div className='container mt-4'>
        <h4>Dashboard</h4>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="textStyle breadcrumb-item active">Home</li>
                <li className="textStyle breadcrumb-item active" aria-current="page">Library</li>
            </ol>
        </nav>

        <div className='row'>
            <div className='mt-5 col-9'>
                <h5>Form Control</h5>
                <div className='rounded-3' style={{background:'#fff', padding:16, }}>
                    <div className='m-4'>
                        <form className="row g-3">
                            <div className="col-md-6">
                                <label className="form-label">Email</label>
                                <input type="email" className="form-control" id="inputEmail4"/>
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Password</label>
                                <input type="password" className="form-control" id="inputPassword4"/>
                            </div>
                            <div className="col-12">
                                <label htmlFor="inputAddress" className="form-label">Address</label>
                                <input type="text" className="form-control" id="inputAddress" placeholder="1234 Main St"/>
                            </div>
                            <div className="col-12">
                                <label htmlFor="inputAddress2" className="form-label">Address 2</label>
                                <input type="text" className="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor"/>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="inputCity" className="form-label">City</label>
                                <input type="text" className="form-control" id="inputCity"/>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="inputState" className="form-label">State</label>
                                <select id="inputState" className="form-select">
                                <option selected>Choose...</option>
                                <option>...</option>
                                </select>
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="inputZip" className="form-label">Zip</label>
                                <input type="text" className="form-control" id="inputZip"/>
                            </div>
                            <div className="col-12">
                                <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="gridCheck"/>
                                <label className="form-check-label" htmlFor="gridCheck">
                                    Check me out
                                </label>
                                </div>
                            </div>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Default switch checkbox input</label>
                                </div>
                                <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Default switch checkbox input</label>
                                </div>
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary">Sign in</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
            <div className='mt-5 col-3'>
                <h5>Cards</h5>
                <div className='rounded-3' style={{background:'#fff', padding:16, }}>
                    
                   
                </div>
            </div>
        </div>

        <div className='mt-5'>
            <h5>Table Example</h5>
            <div className='rounded-3' style={{background:'#fff', padding:32 }}>
                <table className="table">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">First</th>
                        <th scope="col">Last</th>
                        <th scope="col">Status</th>
                        <th scope="col">Handle</th>
                        <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Mark</td>
                            <td>Otto</td>
                            <td><span className="badge rounded-pill bg-primary">Info</span></td>
                            <td>@mdo</td>
                            <td>
                                <button type="button" className="btn btn-outline-primary me-1">
                                    <i className='bx bx-edit' ></i>
                                </button>
                                <button type="button" className="btn btn-outline-danger me-1">
                                    <i className='bx bx-trash-alt' ></i>
                                </button>
                                <button type="button" className="btn btn-outline-success">
                                    <i className='bx bx-cog' ></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                        <th scope="row">2</th>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td><span className="badge rounded-pill bg-danger">Delete</span></td>
                        <td>@fat</td>
                        <td>
                                <button type="button" className="btn btn-outline-primary me-1">
                                    <i className='bx bx-edit' ></i>
                                </button>
                                <button type="button" className="btn btn-outline-danger me-1">
                                    <i className='bx bx-trash-alt' ></i>
                                </button>
                                <button type="button" className="btn btn-outline-success">
                                    <i className='bx bx-cog' ></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                        <th scope="row">3</th>
                        <td>Larry the Bird</td>
                        <td>Thornton</td>
                        <td><span className="badge rounded-pill bg-success">Active</span></td>
                        <td>@fat</td>
                        <td>
                                <button type="button" className="btn btn-outline-primary me-1">
                                    <i className='bx bx-edit' ></i>
                                </button>
                                <button type="button" className="btn btn-outline-danger me-1">
                                    <i className='bx bx-trash-alt' ></i>
                                </button>
                                <button type="button" className="btn btn-outline-success">
                                    <i className='bx bx-cog' ></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
  )
}

export default HomeScreen

