import React from 'react'
import UserInfo from '../UserInfo/userInfo'
import OptionMenu from '../OptionMenu/optionMenu'
import { Accordion, Button, ListGroup } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import * as URI from "uri-js";
import SideBar from '../SideBar/sideBar';
import { useNavigate } from 'react-router-dom';

const Menu = () => {

    const auth = useAuth();

    const navigate = useNavigate();

    const homeIcon = (
        <i className='bx bx-home me-3' style={{color:'#fff'}} ></i>
    );
    const notificationIcon = (
        <i className='bx bxs-bell-ring me-3' style={{color:'#fff'}} ></i> 
    );
    const catalogIcon = (
        <i className='bx bx-basket me-3' style={{color:'#fff'}} ></i> 
    );
    const locationIcon = (
        <i className='bx bxs-edit-location me-3' style={{color:'#fff'}} ></i> 
    );
    const fulfillmentIcon = (
        <i className='bx bx-selection me-3' style={{color:'#fff'}} ></i> 
    );

  return (
    <div className=''>
        <div className='d-flex justify-content-center'>
            <img className='mt-4 mx-2' src={"https://poppinpay.com/wp-content/uploads/2018/05/poppinpay-logo-white-x2.png"} height={32}></img>
        </div>
        <UserInfo></UserInfo>
        <div className='d-flex justify-content-center mt-4'>
            <i className='bx bxs-bulb mx-2' style={{color:'#fff'}} ></i>
            <i className='bx bxs-cog mx-2' style={{color:'#fff'}} ></i>
            <i className='bx bxs-bell mx-2' style={{color:'#fff'}} ></i>
            <i className='bx bxs-user mx-2' style={{color:'#fff'}} ></i>
        </div>
        
        <div className='mt-5 mx-2'>
            <SideBar>
                <SideBar.Item title={'Locations'}  onClick={() => {}} />                
                <SideBar.Dropdown title={'Catalog Management'}>
                    <SideBar.Item title={'Categories'} onClick={() => {}} />
                    <SideBar.Item title={'Items'} onClick={() => {}} />
                    <SideBar.Item title={'Modifier Lists'} onClick={() => {}} />
                    <SideBar.Item title={'Tax Exclusion Rules'} onClick={() => {}} />
                </SideBar.Dropdown>
                <SideBar.Dropdown title={'Content Management'}>
                    <SideBar.Item title={'Mobile Content'}  onClick={() => {}} />
                    <SideBar.Item title={'Web Content'} onClick={() => {}} />
                    <SideBar.Item title={'Shared Content'} onClick={() => {}} />
                </SideBar.Dropdown>
                <SideBar.Item title={'Coupons'} onClick={() => {}} />
                <SideBar.Dropdown title={'Administration'}>
                    <SideBar.Item title={'Team Members'}  onClick={() => { navigate('/admin/team-members')}} />
                </SideBar.Dropdown>                
            </SideBar>
            <Button onClick={() => auth.signoutRedirect()}>Logout</Button>
        </div>
    </div>
  )
}

export default Menu
