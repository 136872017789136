import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../';

export interface ApiState
{
	initialized: boolean
	initializing: boolean
}

const initialState:ApiState = {
	initialized: false,
    initializing: false,
};

const apiSlice = createSlice({
	name: 'api',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		initializing: (state) => {
			state.initialized = false;
			state.initializing = true;
		},

		initialized: (state) => {
			state.initializing = false;
			state.initialized = true;
		}
	}
});

export const { initializing, initialized } = apiSlice.actions

export const reducer = apiSlice.reducer