import { IServerSideGetRowsRequest } from 'ag-grid-enterprise';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export type ApiError = {
	error: true
	statusCode: number
	statusText: string
	data: any
};


export type TeamMember = {
	id:string
    firstName:string
    lastName:string
    emailAddress:string
}

export type RowsResult<T> = {
	rowData:T[]
    rowCount?: number;
    groupLevelInfo?: any;
    pivotResultFields?: string[];	
}

export function IsApiError<T>(response:T|ApiError): response is ApiError {
	return (response as ApiError).error === true;
}

export class ApiClient {

	private static baseUrl:string =  'https://localhost:5005/' // 'https://admin-api-staging.poppinpay.com/';

	private static accessToken:string|null = null;

	public static setAccessToken(accessToken:string|null):void {
		console.log('ApiClient AccessToken is now [' + accessToken + ']');
		ApiClient.accessToken = accessToken;
	}

	private static transformResponse<T>(response:AxiosResponse<T>):T|ApiError {
		if (response.status >= 200 && response.status < 300)
			return response.data;
		return {
			error: true,
			statusCode: response.status,
			statusText: response.statusText,
			data: response.data
		};		
	}

	public static async getTeamMembers(query:IServerSideGetRowsRequest): Promise<RowsResult<TeamMember>|ApiError> {
		const response = await axios.post('api/v1.0/TeamMembers', {query}, await ApiClient.getDefaultConfig());
		return this.transformResponse(response);
	}	

	private static async getDefaultConfig():Promise<AxiosRequestConfig> {
		let headers:any = {
			
		};

		const accessToken = ApiClient.accessToken;

		if (accessToken) {
			headers['Authorization'] = 'Bearer ' + accessToken;
		}

		return {
			baseURL: ApiClient.baseUrl,
			headers: headers,
			validateStatus: null
		};
	}


}