import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useAuth } from "react-oidc-context";

function LoginScreen() {

    const auth = useAuth();

    const loginWithSquareClicked = () => {
        auth.signinRedirect({acr_values:'idp:Square'});
    };

    const loginWithCloverClicked = () => {
        auth.signinRedirect({acr_values:'idp:Clover'});
    };

    return (
        <div className="d-flex vh-100 justify-content-center align-items-center">
            <Row className="">
                <Col className="col-md-6 offset-md-3">
                    <div className="bg-white p-4 p-md-5 rounded shadow-sm">
                        <Row>
                            <Col>
                                <div className="mb-3">
                                    <h3>Log in to PoppinPay Dashboard</h3>
                                </div>
                            </Col>
                        </Row>
                        <Form>
                            <Row className="gy-1 gy-md-1 overflow-hidden">
                                <Form.Group className="mb-3" controlId="loginForm.CompanyToken">
                                    <Form.Label>Company token</Form.Label>
                                    <Form.Control type="text" placeholder="A1B2C3" />
                                </Form.Group>                                
                                <Form.Group className="mb-3" controlId="loginForm.EmailAddress">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" placeholder="name@example.com" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="loginForm.Password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder="Password" />
                                </Form.Group>                                    
                                <Col>
                                    <div className="d-grid">
                                        <Button size="lg" variant="outline-primary">Log in with Email &amp; Password</Button>
                                    </div>
                                </Col>
                            </Row>
                            <div className="d-flex flex-row my-1 align-items-center">
                                <div className="bg-secondary-subtle" style={{height: "1px", width:"100%"}}></div>
                                <p className="text-muted font-400 mx-4 my-1">Or</p>
                                <div className="bg-secondary-subtle" style={{height: "1px", width: "100%"}}></div>
                            </div>
                        </Form>
                        <Row>
                            <Col>
                                <div className="d-flex gap-4">
                                    <Button size="lg" variant="light" onClick={loginWithSquareClicked}>
                                        <svg width="64" height="16" viewBox="0 0 88 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.6761 1.54849e-07H18.3239C19.299 -0.000282679 20.2344 0.386894 20.924 1.07633C21.6136 1.76576 22.0011 2.70095 22.0011 3.6761V18.3228C22.0011 20.3537 20.3547 22 18.3239 22H3.6761C1.64567 21.9994 0 20.3533 0 18.3228V3.6761C0 1.64584 1.64584 1.54849e-07 3.6761 1.54849e-07ZM16.8434 18.0005C17.4842 18.0005 18.0037 17.481 18.0037 16.8402L18.0005 5.16083C18.0005 4.52004 17.481 4.00058 16.8402 4.00058H5.1619C4.854 4.00058 4.55872 4.12297 4.3411 4.34079C4.12348 4.55861 4.00137 4.854 4.00165 5.1619V16.8402C4.00165 17.481 4.52111 18.0005 5.1619 18.0005H16.8434Z" fill="#3E4348"/>
                                            <path d="M8.66615 13.9828C8.30039 13.9799 8.00543 13.6826 8.00544 13.3168V8.65442C8.00459 8.47722 8.07438 8.30698 8.19939 8.18138C8.32439 8.05578 8.49429 7.98517 8.67149 7.98517H13.3403C13.5174 7.98545 13.6871 8.05615 13.8121 8.18169C13.937 8.30724 14.0069 8.47731 14.0063 8.65442V13.3157C14.0069 13.4928 13.937 13.6629 13.8121 13.7884C13.6871 13.914 13.5174 13.9847 13.3403 13.985L8.66615 13.9828Z" fill="#3E4348"/>
                                            <path d="M33.1959 10.0196C32.5149 9.83388 31.8702 9.65883 31.3696 9.43575C30.4431 9.0216 30.0119 8.44734 30.0119 7.62972C30.0119 6.08414 31.5062 5.3882 32.9942 5.3882C34.4084 5.3882 35.6434 5.97313 36.4728 7.03412L36.5293 7.1067L37.7248 6.17167L37.6672 6.09908C36.5646 4.69653 34.9315 3.92801 33.0667 3.92801C31.8254 3.92801 30.6875 4.26317 29.8646 4.87265C28.9381 5.55044 28.4492 6.53351 28.4492 7.70657C28.4492 10.4338 31.0173 11.0987 33.0817 11.6335C35.1706 12.1843 36.4504 12.6027 36.4504 14.1952C36.4504 15.7632 35.1823 16.7762 33.2204 16.7762C32.2502 16.7762 30.4538 16.519 29.3245 14.7941L29.2722 14.7129L28.0148 15.6234L28.0639 15.6971C29.1313 17.3131 30.9736 18.2407 33.1244 18.2407C36.0458 18.2407 38.0098 16.5915 38.0098 14.1387C38.0098 11.3314 35.3392 10.6045 33.1959 10.0196Z" fill="#3E4348"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M47.5395 9.45282V7.97662H48.9452V21.9979H47.5395V16.52C46.7368 17.6205 45.5328 18.2225 44.1174 18.2225C41.4447 18.2225 39.5767 16.0824 39.5767 12.9923C39.5767 9.90219 41.4489 7.745 44.1174 7.745C45.5232 7.745 46.7272 8.35021 47.5395 9.45282ZM41.0583 12.9752C41.0583 15.8358 42.6967 16.8552 44.2305 16.8552L44.2337 16.8562C46.2415 16.8562 47.5395 15.3192 47.5395 12.9752C47.5395 10.6312 46.2394 9.11552 44.2305 9.11552C41.8919 9.11552 41.0583 11.1094 41.0583 12.9752Z" fill="#3E4348"/>
                                            <path d="M58.239 7.97662V13.511C58.239 15.4484 56.9122 16.8552 55.0848 16.8552C53.567 16.8552 52.8284 15.9543 52.8284 14.1024V7.97662H51.4226V14.3895C51.4226 16.7911 52.728 18.2246 54.914 18.2246C56.276 18.2246 57.4459 17.6472 58.24 16.5915V17.9962H59.6458V7.97662H58.239Z" fill="#3E4348"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M62.293 9.02907C63.3294 8.21465 64.7362 7.7482 66.1505 7.7482C68.3846 7.7482 69.7177 8.85935 69.7135 10.723V17.9984H68.3067V16.8872C67.5968 17.7763 66.57 18.2268 65.2486 18.2268C63.0956 18.2268 61.7571 17.0494 61.7571 15.1559C61.7571 12.6934 64.0776 12.307 65.0661 12.1426C65.2272 12.116 65.3937 12.0904 65.5601 12.0647L65.5603 12.0647L65.5663 12.0638C66.9168 11.8559 68.3109 11.6414 68.3109 10.4957C68.3109 9.19878 66.6276 9.09845 66.1121 9.09845C65.2016 9.09845 63.9154 9.3685 63.0412 10.1263L62.9612 10.1957L62.2268 9.08137L62.293 9.02907ZM63.2248 15.0769C63.2248 16.6823 64.7362 16.8562 65.3863 16.8562H65.3873C66.8006 16.8562 68.3131 16.1027 68.3099 13.985V12.5354C67.6242 12.9685 66.6483 13.1377 65.7778 13.2886L65.7631 13.2912L65.3265 13.3691C63.9325 13.6274 63.2248 13.9604 63.2248 15.0769Z" fill="#3E4348"/>
                                            <path d="M77.8577 8.16554C77.5236 7.92752 76.9974 7.78555 76.4487 7.78555C75.3213 7.80037 74.2664 8.34386 73.5998 9.25322V7.97235H72.1941V17.9909H73.5998V12.6326C73.5998 10.2566 74.9352 9.19237 76.2576 9.19237C76.6447 9.1872 77.0279 9.26852 77.3795 9.4304L77.4745 9.48057L77.9196 8.20611L77.8577 8.16554Z" fill="#3E4348"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M78.2697 13.0136C78.2697 9.91394 80.2027 7.7482 82.9662 7.7482C85.6282 7.7482 87.4887 9.67057 87.4834 12.4276C87.4826 12.6673 87.4694 12.9067 87.4439 13.1449L87.4353 13.2271H79.7501C79.7853 15.4334 81.1132 16.8562 83.1508 16.8562C84.3186 16.8562 85.3304 16.3813 85.9997 15.5177L86.0605 15.4388L87.0788 16.346L87.0223 16.4143C86.3455 17.2394 85.1116 18.2236 83.0729 18.2236C80.2016 18.2236 78.2697 16.1304 78.2697 13.0136ZM82.9277 9.09738C81.2103 9.09738 79.9924 10.2277 79.8024 11.9879H85.9976C85.8759 10.5725 85.0113 9.09738 82.9277 9.09738Z" fill="#3E4348"/>
                                        </svg>
                                    </Button>
                                    <Button size="lg" variant="light" onClick={loginWithCloverClicked}>
                                        <svg version="1.1" id="layer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 652 652" width="64" height="64">
                                            <g>
                                                <path className="st0" d="M268.3,347.7c-9.2,11.9-23.4,18.9-38.4,19c-25.6,0-46-18.3-46-42.5c0.1-11.7,5-22.8,13.5-30.8
                                                    c8.5-8,19.9-12.2,31.6-11.7c15,0.1,29.2,7.1,38.4,19l-17,12.1c-4.5-7.5-12.6-12.2-21.3-12.3c-13.1,0-23.8,10.6-23.9,23.7
                                                    c0.4,13.4,11.5,23.9,24.9,23.7c8.7-0.1,16.7-4.4,21.7-11.5L268.3,347.7z"/>
                                                <rect x="277.8" y="248.4" className="st0" width="21.3" height="116.5"/>
                                                <path className="st0" d="M355.5,348.6c13.8,0.1,25.1-10.7,25.6-24.5c-0.7-13.6-12-24.3-25.6-24.3c-13.6,0-24.9,10.7-25.6,24.3
                                                    C330.4,337.9,341.8,348.8,355.5,348.6 M355.6,281.6c11.8-0.6,23.3,3.6,31.9,11.6c8.7,8,13.7,19.1,14.1,30.9
                                                    c-0.4,11.8-5.5,22.9-14.1,30.9c-8.7,8-20.2,12.2-31.9,11.6c-25.6,0-46.2-18.5-46.2-42.5C309.3,300.2,329.8,281.6,355.6,281.6"/>
                                                <polygon className="st0" points="398.1,283.3 423.1,283.3 443.4,326.9 465.3,283.3 488.5,283.3 443.4,369.9 	"/>
                                                <path className="st0" d="M549.1,312.8c-3.3-8-11.2-13.3-19.8-13.2c-9-0.1-17.3,5-21.1,13.2H549.1L549.1,312.8z M562.8,355.7
                                                    c-8.9,7.2-20,11.1-31.4,11c-25.6,0-46.2-18.5-46.2-42.5c0-11.6,4.7-22.7,13.1-30.7c8.4-8,19.7-12.3,31.2-11.8
                                                    c11.4-0.4,22.4,4,30.4,12s12.3,19.1,11.9,30.4v6.1h-65.5c3.3,11,13.4,18.4,24.9,18.3c7.1,0.2,13.9-2.7,18.7-8L562.8,355.7z
                                                    M582.1,319.1c0-19.5,14.9-35.8,38.9-35.8v19.1c-5.1-0.3-10,1.7-13.4,5.5c-3.4,3.7-5,8.8-4.4,13.8v43.2h-21.1V319.1z"/>
                                                <path className="st1" d="M87.1,286.5c0.1-13.4-7.9-25.5-20.3-30.7c-12.3-5.2-26.6-2.4-36.1,7s-12.4,23.7-7.3,36.1
                                                    c5.1,12.4,17.2,20.5,30.6,20.5h33.1L87.1,286.5z M96.7,286.5c-0.1-13.4,7.9-25.5,20.3-30.7c12.3-5.2,26.6-2.4,36.1,7
                                                    c9.5,9.4,12.4,23.7,7.3,36.1c-5.1,12.4-17.2,20.5-30.6,20.5H96.7L96.7,286.5z M96.7,361.8c-0.1,13.4,7.9,25.5,20.3,30.7
                                                    s26.6,2.4,36.1-7c9.5-9.4,12.4-23.7,7.3-36.1c-5.1-12.4-17.2-20.5-30.6-20.5H96.7L96.7,361.8z M54.1,385.4
                                                    c13.1,0,23.7-10.5,23.8-23.6v-23.6H54.1c-13,0-23.6,10.6-23.6,23.6C30.5,374.8,41,385.4,54.1,385.4z M87.1,361.8
                                                    c0.1,13.4-7.9,25.5-20.3,30.7s-26.6,2.4-36.1-7s-12.4-23.7-7.3-36.1c5.1-12.4,17.2-20.5,30.6-20.5h33.1L87.1,361.8z"/>
                                            </g>
                                        </svg>
                                    </Button>
                                </div>                   
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default LoginScreen;